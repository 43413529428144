var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-main"},[_c('div',{staticClass:"role-name"},[_c('span',[_vm._v("角色名称：")]),_c('span',{staticClass:"c-green"},[_vm._v(_vm._s(_vm.role.name))])]),_vm._l((_vm.permissionMap),function(value,key){return _c('div',{key:key},[_c('div',{staticClass:"table-title m-b-10"},[_c('vxe-switch',{staticClass:"m-r-10",on:{"change":function($event){return _vm.allSwitch(key)}},model:{value:(_vm.permissionMap[key][0].assigned),callback:function ($$v) {_vm.$set(_vm.permissionMap[key][0], "assigned", $$v)},expression:"permissionMap[key][0].assigned"}}),_c('strong',[_vm._v(_vm._s(key))])],1),_c('vxe-grid',{staticClass:"m-b-10",attrs:{"size":"mini","border":"","highlight-hover-row":"","min-height":32,"show-header":false,"mouse-config":{ selected: false },"edit-config":{ trigger: 'click', mode: 'cell' },"data":_vm.permissionMap[key],"columns":_vm.tableColumn,"keyboard-config":{
        enterToTab: false,
        isArrow: true,
        isDel: false,
        isEnter: false,
        isTab: true,
        isEdit: true
      }},scopedSlots:_vm._u([{key:"default_operate",fn:function({ row }){return [[_c('vxe-switch',{on:{"change":function($event){return _vm.changeRoleEvent({ row })}},model:{value:(row.assigned),callback:function ($$v) {_vm.$set(row, "assigned", $$v)},expression:"row.assigned"}}),_c('vxe-button',{staticClass:"m-l-5",attrs:{"icon":"el-icon-delete","title":"删除","circle":""},on:{"click":function($event){return _vm.itemDelete(row)}}})]]}}],null,true)})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }